import React from 'react';
import './App.css'; // Asegúrate de que esta ruta sea la correcta

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>¡Bienvenida a tu mundo mágico, Paulina!</h1>
      </header>
      <main className="main-content">
        <p>¡Hola Paulina, qué alegría que estés aquí! 👋</p>
        <p>Como Ponyo en su mundo de maravillas, te invito a sumergirte en una aventura encantadora. ¿Lista para descubrir los secretos y emociones escondidos en mis palabras? Un solo clic puede abrir un universo de posibilidades.</p>
        <a href="mailto:business.bady@gmail.com" className="button email-link" style={{ backgroundColor: '#FF6347', color: 'white', padding: '15px 32px', textAlign: 'center', textDecoration: 'none', display: 'inline-block', fontSize: '16px', margin: '4px 2px', cursor: 'pointer' }}>
          Descubre más - Envíame un correo
        </a>
      </main>
    </div>
  );
}

export default App;
